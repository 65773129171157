import * as React from "react";
import { Container, Row} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../components/css/NewsDetail.scss";
import { Link } from "gatsby";
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';


const EdgeqPressKit6 = () => (
  <Layout>
    <Seo title="EdgeQ | EdgeQ and Vodafone Partner" />


    <section className="News-detail">
        <Container>

                <Row>

                  <div className="detail-head">
                   <Link to="/media/#August" className="back-btn"><FiArrowLeft/> Company Announcements</Link>

                  <h2>EdgeQ and Vodafone Partner on World’s First 5G/4G Software Defined, In-Line L1 Acceleration based Open RAN Platform</h2>
                  </div>

                  <div className="detail-para">
                    
              <p className="text-left"><i>EdgeQ Collaborates with Vodafone on Industry’s First Multi-Carrier, Massive MIMO 5G Open RAN Platform</i></p>

              <div className="ajit-social">
                <ul className="social-icons">
                  <li>  
                    <a aria-label="Save" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://twitter.com/edgeq_inc?lang=en" target="_blank" rel="noopener noreferrer"><FaTwitter/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" rel="noopener noreferrer"><FaYoutube/></a>
                  </li>
                </ul>
              </div>
            <p><b>SANTA CLARA, CA</b> – October 26, 2022  – <Link to="/">EdgeQ Inc</Link>, a leader in 5G wireless infrastructure, and Vodafone, a leading telecommunications mobile operator in Open Radio Access network (ORAN), have partnered to develop the next generation, software-programmable 5G ORAN platforms. The collaboration leverages upon ORAN to deliver the world’s first open programmable, fully in-line L1 acceleration card capable of supporting multi-carrier, massive MIMO. This massively integrated platform uniquely converges the entire 4G/5G Physical layer onto a single card to address the 5G macro cell deployments.</p>

            <p>A pioneer of ORAN, Vodafone is innovating on a new class of software-defined, compute platforms that converge 4G and 5G in-line acceleration to deliver high performance, high efficiency, and cost-effective solutions. By leveraging EdgeQ’s fully hardened ORAN 4G/5G PHY, and support for the evolving ORAN Alliance principles for open architectures and specifications, the accelerator card converges compute and connectivity in a compact and scalable platform.</p>

            <p>“Vodafone remains committed as a market leader in driving massive 5G ORAN deployments. We see EdgeQ enriching the ORAN ecosystem with its high capacity in-line L1 acceleration card, bringing fresh thinking and innovation to the overall industry. EdgeQ enables operators like Vodafone to simply operationalize ORAN,” said Paco Martin, Head of OpenRAN, Network Architecture, Vodafone.</p>

            <p>By compacting 4G, 5G, networking, and cloud functionalities into a single-chip, EdgeQ enables a highly dense, highly scalable ORAN solution that supports multi-carrier, multi-tenet, and multi-users. The scalable architecture packs an unprecedented throughput, all within a compact power envelope. </p>

            <p>“Our partnership with Vodafone elevates the aspirations of OpenRAN. We are able to assemble best-of-breed hardware and software solutions to set new industry benchmarks. This in turn enables operators to embrace a holistic 4G-5G platform that is frictionlessly scalable, massively integrated, but yet openly customizable,” said Vinay Ravuri, CEO and Founder of EdgeQ.</p>

            <p>EdgeQ's founding principles of an open, configurable 5G platform is premised on the fundamental spirit of Open RAN that empowers operators with optionality and customizable solutions. The announcement with Vodafone coincides with the first Joint ORAN Alliance and Telecom Infra Project Conference in Madrid, signifying the growing maturity of the OpenRAN Community.</p>

            <p>To learn more about how EdgeQ is pioneering the 5G infrastructure market, please visit <Link to="/">www.edgeq.io</Link>.</p>
  
              <b>About EdgeQ</b>
              <br />
              <br />
              <p>EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA, with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and Broadcom, EdgeQ is pioneering converged connectivity and AI that is fully software-customizable and programmable. The company is backed by world-renowned investors. To learn more about EdgeQ, visit  <Link to="/">www.edgeq.io</Link></p>
              {/* <b>Contacts</b>
              <br />
              <br />
              <a target="_blank" href="https://goo.gl/maps/qwqsKwcTcEVokx1e6">Jordan Tewell, 10Fold Communications</a>
              <br />
              <a href="mailto:edgeq@10fold.com">edgeq@10fold.com</a>
              <br />
              <a href="tel:+4156666066">(415) 666-6066</a> */}
</div>
                </Row>
        </Container>
    </section>


  </Layout>
);

export default EdgeqPressKit6;